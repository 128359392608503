import React from 'react'
import { Link } from 'gatsby'
import 'bootstrap/dist/css/bootstrap.css'
import { Navbar, Nav, Image, Col, Button } from 'react-bootstrap'
import Logo from '../images/digipay-logo.png'
import './css/layout.css'
import './css/header.css'
import Hero from 'images/carousel-items/1.jpg'
import HostName from '../data/static/HostNames'

const Header = () => (
  <Navbar bg="light" expand="xl" className="header">
    <Navbar.Brand>
      <Link to="/">
        <Image src={Logo} alt="digipay-logo" />
      </Link>
    </Navbar.Brand>
    <Navbar.Toggle aria-controls="basic-navbar-nav" />
    <Navbar.Collapse className="justify-content-end">
      <Nav justify className="ml-auto">
        <Col className="header__btn-nav">
          <Link className="nav-link text-danger" align="center" to="/">
            HOME
          </Link>
          {/* <Nav.Link href="/" className="text-danger" align="center">HOME</Nav.Link> */}
        </Col>
        <Col className="header__btn-nav">
          <Link className="nav-link text-danger" align="center" to="/promos">
            PROMOS
          </Link>
          {/* <Nav.Link href="/promos" className="text-danger" align="center">PROMOS</Nav.Link> */}
        </Col>
        <Col className="header__btn-nav">
          <Link
            className="nav-link text-danger"
            align="center"
            to="/QuestionsSection"
          >
            FAQs
          </Link>
          {/* <Nav.Link href="/QuestionsSection" className="text-danger" align="center">FAQs</Nav.Link> */}
        </Col>
        <Col className="header__btn-nav">
          <Link className="nav-link text-danger" align="center" to="/terms">
            TERMS
          </Link>
          {/* <Nav.Link href="/terms" className="text-danger" align="center">TERMS</Nav.Link> */}
        </Col>
        <Col className="header__btn-nav">
          <Link className="nav-link text-danger" align="center" to="/policy">
            POLICY
          </Link>
          {/* <Nav.Link href="/policy" className="text-danger" align="center">POLICY</Nav.Link> */}
        </Col>
        <Col className="header__btn-nav">
          <Link className="nav-link text-danger" align="center" to="/careers">
            CAREERS
          </Link>
          {/* <Nav.Link href="/careers" className="text-danger" align="center">CAREERS</Nav.Link> */}
        </Col>
        <Col className="header__btn-nav">
          <Nav.Link
            href={`/branch-locator`}
            className="text-danger"
            align="center"
          >
            DIGIPADALA BRANCH LOCATOR
          </Nav.Link>
        </Col>
        <Button
          variant="danger"
          href={`/sign-in`}
          className="header__btn-login"
        >
          LOG IN
        </Button>
        <Button
          variant="outline-danger"
          href={`/sign-up`}
          className="header__btn-signup"
        >
          SIGN UP
        </Button>
      </Nav>
    </Navbar.Collapse>
  </Navbar>
)
export default Header
