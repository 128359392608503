import React from 'react'
import 'bootstrap/dist/css/bootstrap.css'
import { Container, Col, Row, Image, Button, Form } from 'react-bootstrap'
import { Link } from 'gatsby'
import './css/footer.css'
import Phone from 'images/icons/phone.svg'
import Email from 'images/icons/email.svg'
import Mobile from 'images/icons/mobile.svg'
import Location from 'images/icons/location.svg'
import Facebook from 'images/icons/fb.svg'
import Instagram from 'images/icons/ig.svg'
import Linkedin from 'images/icons/linkedin.svg'
import FooterSignup from 'components/FooterSignup'
import CookieConsent from 'react-cookie-consent'
import HostName from '../data/static/HostNames'

const Footer = () => (
  <div className="footer">
    <Row className="footer__content-list">
      <Col className="footer__content-item" xs={12} md={3}>
        <h6>NAVIGATE</h6>
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/QuestionsSection">FAQs</Link>
          </li>
          <li>
            <Link to="/terms">Terms</Link>
          </li>
          <li>
            <Link to="#about-us">About Us</Link>
          </li>
          <li>
            <a href="https://status.digipay.ph/">Status</a>
          </li>
          <li>
            <Link to="/promos">Promos</Link>
          </li>
          <li>
            <a
              href={`https://${process.env.GATSBY_REACT_APP_HOSTNAME}/branch-locator/`}
            >
              DigiPadala Branch Locator
            </a>
          </li>
        </ul>
      </Col>

      <Col className="footer__content-item" xs={12} md={3}>
        <div>
          <h6>SERVICES</h6>
          <ul>
            <li>
              <a href="/sign-in">Digipay</a>
            </li>
            <li>
              <Link to="/DigiPadalaAgent">DigiPadala - Agent</Link>
            </li>
            <li>
              <Link to="/DigiPadalaCustomer">DigiPadala - Customer</Link>
            </li>
            {/* <li><a href="https://healthguard.digipay.ph/">Healthguard</a></li> */}
            {/* <li><a href="https://cashaloan.digipay.ph/">Cashaloan</a></li> */}
            <li>
              <Link to="/Digiprotec+Info">
                DigiProtec<sup>+</sup>
              </Link>
            </li>
          </ul>
        </div>
      </Col>

      <Col className="footer__content-item" xs={12} md={3}>
        <div>
          <h6>CONTACT</h6>
          <Row>
            <Col xs={1}>
              <Image
                className="footer-signup__contact-item-icons"
                src={Phone}
                alt="phone-icon"
              />
            </Col>
            <Col xs={9}>
              <p>8 +254 6621</p>
            </Col>
          </Row>
          <Row>
            <Col xs={1}>
              <Image
                className="footer-signup__contact-item-icons"
                src={Email}
                alt="email-icon"
              />
            </Col>
            <Col xs={9}>
              <p>info@digipay.ph</p>
            </Col>
          </Row>
          <Row>
            <Col xs={1}>
              <Image
                className="footer-signup__contact-item-icons"
                src={Mobile}
                alt="mobile-icon"
              />
            </Col>
            <Col xs={9}>
              <p>0908 212 4828(Smart)</p>
              <p>0906 374 0371(Globe)</p>
            </Col>
          </Row>
          <Row>
            <Col xs={1}>
              <Image
                className="footer-signup__contact-item-icons"
                src={Location}
                alt="location-icon"
              />
            </Col>
            <Col xs={9}>
              <p>
                6F, G Business Hub, 728 Shaw Boulevard, Mandaluyong City, 1552
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={1}>
              <a href="https://www.facebook.com/digipayph/">
                <Image
                  className="footer-signup__contact-item-icons"
                  src={Facebook}
                  alt="fb-logo"
                />
              </a>
            </Col>
            <Col xs={9}>
              <a href="https://www.facebook.com/digipayph/">
                <p>/digipayph</p>
              </a>
            </Col>
          </Row>
          <Row>
            <Col xs={1}>
              <a href="https://www.instagram.com/digipayph/">
                <Image
                  className="footer-signup__contact-item-icons"
                  src={Instagram}
                  alt="ig-logo"
                />
              </a>
            </Col>
            <Col xs={9}>
              <a href="https://www.instagram.com/digipayph/">
                <p>@digipayph</p>
              </a>
            </Col>
          </Row>
          <Row>
            <Col xs={1}>
              <a href="https://ph.linkedin.com/company/digipay">
                <Image
                  className="footer-signup__contact-item-icons"
                  src={Linkedin}
                  alt="linkedin-logo"
                />
              </a>
            </Col>
            <Col xs={9}>
              <a href="https://ph.linkedin.com/company/digipay">
                <p>/digipay</p>
              </a>
            </Col>
          </Row>
        </div>
      </Col>

      <Col className="footer__content-item" xs={12} md={3}>
        <div>
          <h6>CAREER</h6>
          <ul>
            <li>
              <Link to="/careers#sales">Sales</Link>
            </li>
            <li>
              <Link to="/careers#accounting">Accounting</Link>
            </li>
            <li>
              <Link to="/careers#tech">Tech</Link>
            </li>
            <li>
              <Link to="/careers#operations">Operations</Link>
            </li>
            <li>
              <Link to="/careers#marketing">Marketing</Link>
            </li>
            <li>
              <Link to="/careers#admin">Admin</Link>
            </li>
          </ul>
        </div>
      </Col>
    </Row>
    <FooterSignup />
    <CookieConsent
      location="bottom"
      buttonText="I understand"
      cookieName="gatsby-gdpr-google-analytics"
    >
      Effective as of 14 April 2020, DIGIPAY employs a third-party service in
      order to analyze DIGIPAY's web traffic, or how you make use of DIGIPAY's
      website. This service may use cookies. For more information, please view
      DIGIPAY's{' '}
      <Link to="/policy" className="footer__cookie-link">
        Privacy Policy
      </Link>
      .
    </CookieConsent>
  </div>
)
export default Footer
