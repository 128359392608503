import React from 'react'
import 'bootstrap/dist/css/bootstrap.css'
import { Col, Row, Button, Form } from 'react-bootstrap'
import './css/footer.css'
import HostName from '../data/static/HostNames'

class FooterSignup extends React.Component {
  constructor() {
    super()
    this.state = {
      email: '',
      contact_number: '',
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(name, value) {
    this.setState({ [name]: value })
  }

  handleSubmit(event) {
    event.preventDefault()
    const queryString = Object.keys(this.state).reduce(
      (queries, name) =>
        queries + `${name}=${encodeURIComponent(this.state[name])}&`,
      ''
    )
    window.location =
      `https://${process.env.GATSBY_REACT_APP_HOSTNAME}/sign-up?` + queryString
  }

  render() {
    return (
      <Form onSubmit={this.handleSubmit}>
        <Row className="footer-signup">
          <Col className="footer-signup__email" xs={12} md={3}>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>
                <p>Email address</p>
              </Form.Label>
              <Form.Control
                name="email"
                type="email"
                placeholder="Enter email"
                value={this.state.email}
                onChange={e => this.handleChange('email', e.target.value)}
              />
              <Form.Text className="footer_signup__helper-text">
                We'll never share your email with anyone else.
              </Form.Text>
            </Form.Group>
          </Col>

          <Col className="footer-signup__password " xs={12} md={3}>
            <Form.Group controlId="formBasicPassword">
              <Form.Label>
                <p>Contact Number</p>
              </Form.Label>
              <Form.Control
                value={this.state.contact_number}
                onChange={e =>
                  this.handleChange('contact_number', e.target.value)
                }
                type="number"
                name="contact_number"
                placeholder="Contact Number"
              />
              <Form.Text className="footer_signup__helper-text">
                Already have an account?
                <a
                  href={`https://${process.env.GATSBY_REACT_APP_HOSTNAME}/sign-in`}
                  className="text-light"
                >
                  {' '}
                  Sign In.
                </a>
              </Form.Text>
            </Form.Group>
          </Col>

          <Col className="footer-signup__submit" xs={12} md={3}>
            <Button className="footer-signup__btn-submit" type="submit">
              <h2>SIGN UP</h2>
            </Button>
          </Col>
        </Row>
      </Form>
    )
  }
}
export default FooterSignup
